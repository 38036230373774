<template>
  <div class="home">
    <div class="hero bg--image"
      :style="`background-image: url('${require(`../assets/images/AT_Packaging_Floating.jpg`)}')`">
      <div class="hero__content text-center">
        <div class="hero__button">
          <router-link class="btn btn__primary btn--large" to="/">Get Strips</router-link>
        </div>
      </div>
    </div>
    <div class="section--lg bg--primary">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h1 class="light">We are a non-profit
              distributing free fentanyl test
              strips across the country.</h1>
            <div class="d-flex justify-content-center gap-1 pt-4">
              <router-link to="/" class="mt-2 btn btn__secondary--primary">Get Strips</router-link>
              <router-link to="/donate" class="mt-2 btn btn__secondary--primary">Donate Now</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <img src="@/assets/images/people3.jpg" class="img-fluid img__fit" />
        <img src="@/assets/images/people2.jpg" class="img-fluid img__fit" />
      </div>
    </div>
    <div class="section bg--light">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <p class="large">
              Our mission is to save lives and combat the fentanyl crisis by distributing free fentanyl testing strips and raising awareness.            </p>
          </div>
        </div>
      </div>
      <div class="container mt-5">
        <FeatureRow></FeatureRow>
      </div>
    </div>
    <div class="section--lg bg--slate">
      <div class="container">
        <div class="split__wrapper">
          <div class="split__content light text-center cta--slim">
            <h2>Become an Abassador</h2>
            <router-link to="/community" class="mt-2 btn btn__secondary--slate">Learn More</router-link>
          </div>
          <div class="split__content light text-center cta--slim">
            <h2>Get Fentanyl Testing Strips</h2>
            <router-link to="/test-strips" class="mt-2 btn btn__secondary--slate">Learn More</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeatureRow from "@/components/FeatureRow.vue";
export default {
  components: { FeatureRow },
  name: "HomePage",
};
</script>
<style lang="scss" scoped>
h1 {
  font-size: 5rem;
}

.hero__content {
  margin-top: auto;

}
.btn--large {
      font-size: 1.5rem;
    padding: 15px 30px;
}
.img__fit {
  height: 600px;
  width: auto;
  object-position: center 20%;
  object-fit: cover;
  flex: 1;
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 2.5rem;
  }
  .split__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
  }

  .split__content {
    flex: 1;
  }

  .cta--slim {
    max-width: 300px;
    margin: 0 auto;
  }
}
</style>