<template>
  <div class="stat__row">
    <div class="stat__wrapper" v-for="item in stats" :key="item.index">
      <div class="stat__number-wrapper">
        <img :src="require(`@/assets/images/${item.outline}.svg`)" alt="" class="stat__image" />
        <h4 class="stat__number">{{ item.number }}</h4>
      </div>
      <h3 class="stat__title">{{ item.title }}</h3>
    </div>
  </div>
</template>
<script>
export default {
  name: "statRow",
  data() {
    return {
      stats: [
        { number: "45.5K", title: "Testing Strips Distributed", outline: "outline_illustration-01" },
        { number: "43", title: "Events Attended", outline: "outline_illustration-02" },
        { number: "125", title: "Brand Ambassadors", outline: "outline_illustration-03" },
      ],
    };
  },
};
</script>
<style scoped>
.stat__row {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: 5rem;
}

.stat__wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.stat__number-wrapper {
  position: relative;
}

.stat__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 225px;
  width: 100%;
  height: 180px;
  margin-bottom: 1rem;
}

.stat__number {
  font-size: 4rem;
  margin-top: 1.25rem;
}

.stat__title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2em;
  margin-top: 4rem;
  font-family: "Macan Book", sans-serif;
  margin-bottom: auto;
}

@media screen and (max-width: 767px) {
  .stat__row {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 6rem;
  }

  .stat__row .col-sm-4:not(:last-of-type) {
    margin-bottom: 2rem;
  }
}</style>