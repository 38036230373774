<template>
  <div class="footer light">
    <div class="container-fluid">
      <div class="split__wrapper" id="footer__row--1">
        <div class="split__content">
          <router-link to="/"> <img class="footer__logo" src="../assets/images/logo-white.svg" />
          </router-link>
        </div>
        <div class="split__content">
          <div class="social__wrapper">
            <a href="https://www.instagram.com/awake_tomorrow/" class="handwritten light">Instagram</a>
            <a href="https://twitter.com/AwakeTomorrow" class="handwritten light">Twitter</a>
          </div>
        </div>
      </div>
      <div class="footer__line">
        <img src="../assets/images/at_line_3.svg" class="img-fluid invert" />
      </div>
      <div class="split__wrapper" id="footer__row--2">
        <div class="split__content footer__icon">
          <img class="invert" src="../assets/images/at_heart_3.svg" />
        </div>
        <div class="split__content donate__wrapper">
          <h4 class="handwritten">Help us help others</h4>
          <router-link to="/donate" class="btn btn__secondary">Donate Now</router-link>
        </div>
      </div>
      <div class="footer__line">
        <img src="../assets/images/at_line_2.svg" class="img-fluid invert" />
      </div>
      <div class="split__wrapper copyright" id="footer__row--3">
        <div class="split__content">
          <div>
            ©{{ new Date().getFullYear() }} Awake Tomorrow, a 501(c)(3) Non Profit
          </div>
        </div>
        <div class="split__content">
          <div class="nav__wrapper">
            <router-link to="/" class="light">Get Testing Strips</router-link>
            <router-link to="/community" class="light">Become an Ambassador</router-link>
            <router-link to="/contact" class="light">Contact Us</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SiteFooter",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.footer {
  background-color: var(--dark);
  padding: 4rem 2rem 2rem 2rem;

  #footer__row--1 {
    padding-bottom: 8rem;
  }

  .footer__logo {
    max-width: 250px;
    width: 100%;
    height: auto;
  }

  .footer__icon {
    max-width: 120px;
    width: 100%;
    height: auto;
  }

  .footer__line {
    padding: 1rem 0;
  }

  .social__wrapper,
  .donate__wrapper,
  .nav__wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    .split__wrapper {
      gap: 1rem;
    }

    .copyright {
      flex-direction: column-reverse;
      text-align: center;
    }

    #footer__row--2 {
      flex-direction: column-reverse;
      gap: 2rem;
    }

    .social__wrapper,
    .nav__wrapper {
      display: flex;
      gap: 0rem;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }

    .donate__wrapper {
      display: flex;
      gap: 1rem;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }

  }
}</style>