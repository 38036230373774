<template>
    <div class="navbar__wrapper">
        <b-navbar toggleable>
            <b-navbar-brand to="/" class="logo">
                <template v-if="this.$route.path == '/'">
                <img src="../assets/images/logo-white.png" class="nav__logo--desktop invert" />
                <img src="../assets/images/at_arc_white.png" class="nav__logo--mobile invert" />
                </template>
                <template v-else>
                <img src="../assets/images/logo-white.png" class="nav__logo--desktop" />
                <img src="../assets/images/at_arc_white.png" class="nav__logo--mobile" />
                </template>
            </b-navbar-brand>
            <div class="ml-auto"><router-link to="/donate" :class="['header__donate__link', this.$route.path == '/' ? '' : 'light']">Donate</router-link></div>
            <b-navbar-toggle target="navbar-toggle-collapse" :class="this.$route.path == '/' ? '' : 'light'">
                <template>
                    <span class="bar"></span><span class="bar"></span>
                </template>
            </b-navbar-toggle>
            <b-collapse id="navbar-toggle-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item v-for="item in global.navigation" :key="item.index" :to="item.path ? item.path : ''"
                        :href="item.link ? item.link : ''" :target="item.link ? '_blank' : ''">{{
                            item.text
                        }}</b-nav-item>
                    <b-nav-item to="/donate" class="btn btn__secondary header__button">Donate Now</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>
<script>
export default {
    name: "SiteHeader",
    components: {
    },
    data() {
        return {
            global: {
                navigation: [
                    {
                        text: "Home",
                        path: "/"
                    },
                    {
                        text: "Shop",
                        link: "https://shop.awaketomorrow.org"
                    },
                    {
                        text: "About Us",
                        path: "/about-us"
                    },
                    {
                        text: "Community",
                        path: "/community"
                    },
                    {
                        text: "Test Strips",
                        path: "/test-strips"
                    },
                    {
                        text: "Fentanyl",
                        path: "/fentanyl"
                    },
                    {
                        text: "Contact",
                        path: "/contact"
                    },
                    {
                        text: "Instagram",
                        link: "https://www.instagram.com/awake_tomorrow/"
                    },
                    {
                        text: "Twitter",
                        link: "https://twitter.com/AwakeTomorrow"
                    },
                    {
                        text: "",
                        link: ""
                    },

                ]
            }
        };
    }
}
</script>
<style lang="scss" scoped>
.header__donate__link {
    font-weight: 600;
    font-size: 1.15rem;
    margin-right: 2rem;
    color: var(--dark);
    text-decoration: none;
}

.navbar__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: all .25s ease-in-out;

    .navbar-toggler {
        color: #000;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .light .bar {
        background-color: #fff;
    }
    .bar {
        display: block;
        width: 32px;
        height: 2px;
        background-color: #000;
        transition: all .2s ease-in-out;
    }

    .collapsed {
        .bar:nth-of-type(2) {
            margin-top: 12px;
        }
    }

    .not-collapsed {
        .bar {
            background-color: #fff;
        }

        .bar:nth-of-type(1) {
            transform: rotate(-45deg);
        }

        .bar:nth-of-type(2) {
            transform: rotate(45deg);
            margin-top: -2px;
        }
    }
}

.navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    width: 100%;
    z-index: 9999999;
    padding: 1rem 2rem;

    ::after {
        display: none;
    }

    &:has(.collapse.show) {
        position: fixed;

        &::after {
            display: block;
            position: fixed;
            height: 100%;
            width: 100%;
            background-color: #00000000;
            top: 0;
            left: 0;
            content: "";
            backdrop-filter: blur(20px);
            transition: all .5s ease-in-out;
        }

        .nav-link {
            opacity: 1;
            transition: all .5s ease-in-out;
        }
    }

    .navbar-toggler {
        padding: 0;
        border: none;
        z-index: 99999;
        font-size: 2.5rem;
        border: none;
        outline: none;
    }

    .navbar-brand {

    }

    .nav__logo--desktop {
        max-width: 250px;
        height: auto;
        display: block;
    }

    .nav__logo--mobile {
        max-width: 50px;
        height: auto;
        display: none;
    }

    .navbar-nav {
        display: block;
        margin-top: 2.5rem;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        column-count: 2;
    }

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
        height: 650px;
        position: absolute;
        width: 100%;
        background-color: var(--primary);
        top: 0;
        left: 0;
        padding: 2rem;
        z-index: 9999;
        transition: all .1s linear;
        box-shadow: 0 5px 15px 0 rgb(0 0 0 / 10%);
        overflow: visible;
    }
    .header__button {
        margin-top: 80px;
        a{
        font-size: 1.15rem!important;
        color: var(--primary)!important;
    }
}
    .nav-link {
        padding: 0;
        font-size: 4rem;
        font-family: "Awake Bold";
        color: #fff !important;
        position: relative;
        display: inline-block;
        opacity: 1;
        line-height: 1.33;

        &::after {
            content: "";
            opacity: 0;
            background-image: url("../assets/images/at_line_1.svg");
            background-position: bottom right;
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            min-height: 100%;
            display: block;
            position: absolute;
            bottom: 0rem;
            left: 0;
            filter: invert(1);
            transition: all .1s ease-in-out;
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .nav-link {
        font-size: 2.5rem !important;
    }

    .navbar {
        padding: 1rem;

        .nav__logo--desktop {
            display: none;
        }

        .nav__logo--mobile {
            display: block;
        }

        .navbar-collapse {
            height: 100vh;
        }

        .navbar-nav {
            display: block;
            margin-top: 2rem;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            column-count: 1;
            text-align: center;
        }
    }
}</style>